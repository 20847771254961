import React from 'react'
import Layout from '../layouts/layout'
import SEO from '../components/seo'
import SecondaryNav from '../components/secondarynav'

import BorderedBox from '../components/borderedbox'
import TitleHeader from '../components/titleheader'
import Card from '../components/card'

// Images

import corporateimage2 from '../assets/images/soical-lp/social_comply_box_d.png'
import corporateimage3 from '../assets/images/soical-lp/social_develop_box_d.png'

import corporatesourcingimage1 from '../assets/images/corporate-responsibility/betterwork-banner.png'
import corporatesourcingimage2 from '../assets/images/corporate-responsibility/slc-banner.png'
import PlanInternational from '../assets/images/corporate-responsibility/PlanInternational.png'
import rise from '../assets/images/corporate-responsibility/RISE_Backronym_Color.png'

import corporatesourcingimage10 from '../assets/images/corporate-responsibility/nirapon-logo.jpg'

import corporatesourcingimage4 from '../assets/images/corporate-responsibility/bsr-banner.jpg'
import corporatesourcingimage5 from '../assets/images/corporate-responsibility/care_logo-big.gif'

import pressdk from '../assets/images/esg_2021/dk-press.png'
import pressmb from '../assets/images/esg_2021/mb-press.png'

import corporatephilanthropicimage6 from '../assets/images/corporate-responsibility/Corporate_Responsibility_Philantrophic_19.jpg'
import corporatephilanthropicimage7 from '../assets/images/corporate-responsibility/Corporate_Responsibility_Philantrophic_21.jpg'
import corporatephilanthropicimage8 from '../assets/images/corporate-responsibility/NAACP_EmpowermentPrograms-Blue-CMYK-01.png'
import corporatephilanthropicimage9 from '../assets/images/corporate-responsibility/Corporate_Responsibility_Philantrophic_29.jpg'
import corporatephilanthropicimage10 from '../assets/images/corporate-responsibility/Corporate_Responsibility_Philantrophic_32.jpg'
import corporatephilanthropicimage11 from '../assets/images/corporate-responsibility/Corporate_Responsibility_Philantrophic_35.jpg'
import corporatephilanthropicimage12 from '../assets/images/corporate-responsibility/Corporate_Responsibility_Philantrophic_23.jpg'
import corporatephilanthropicimage13 from '../assets/images/corporate-responsibility/Delivering_Good_Logo-2@3x.jpg'

import internalesgdk from '../assets/images/esg_2021/esg_lp_header_dk.jpg'
import internalesgmb from '../assets/images/esg_2021/esg_lp_header_mobile.jpg'
import esgPdf2022 from '../assets/images/TCP-2022-ESG-Report-Final.pdf'

import HumanRightsPolicy2021 from '../assets/images/TCP_Human_Rights_Policy.pdf'
import VendorCodeConduct2021 from '../assets/images/VendorCodeConduct2021.pdf'
import StatementXinjiang2021 from '../assets/images/StatementXinjiang2021.pdf'
import COVIDPandemic2021 from '../assets/images/COVIDPandemic2021.pdf'
import TCPOccupationalHealthSafetyPolicy from '../assets/images/TCPOccupationalHealthSafetyPolicy_5.31.22.pdf'
import CanadaForcedChildLabourSupplyChainsAct from '../assets/images/CanadaForcedLaborFilingReport.pdf'

// import DEVELOPinitiative2021 from '../assets/images/DEVELOPinitiative2021.pdf'

import { trackPageData, getPageDetails } from '../analytics'

class ResponsibleSourcing extends React.PureComponent {
  constructor(props) {
    super(props)

    let dataManager = {
      pageDetails: {},
    }
    if (typeof window !== `undefined`) {
      if (window.dataManager) {
        dataManager = window.dataManager
      }
    }
    dataManager.pageDetails = getPageDetails()
  }

  componentDidMount() {
    trackPageData()
  }

  render() {
    return (
      <Layout>
        <SEO
          title="Social | The Children's Place Corporate Website"
          description="The Children's Place is committed to responsible sourcing practices in our supply chain. Learn more on The Children's Place Corproate Website."
        />
        <div
          style={{
            position: `relative`,
          }}
          class="row"
        >
          <a href={`${esgPdf2022}`} target="_blank">
            <div class="col-md-12 col-sm-12 col-xs-12">
              <TitleHeader
                image={internalesgdk}
                mbheaderimage={internalesgmb}
                title="ENVIRONMENT, SOCIAL &amp; GOVERNANCE"
                addClass="corporateTitle esgTitle"
              />
            </div>
          </a>
          <br />

          <a
            class="esg-postion-lb"
            href={`https://newsplace.childrensplace.com/the-childrens-place-issues-2021-annual-esg-report/`}
            target="_blank"
          >
            <div class="col-12">
              <img src={pressdk} className="img-fluid hidden-xs" alt="" />
              <img src={pressmb} className="img-fluid visible-xs" alt="" />
            </div>
          </a>
        </div>

        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <SecondaryNav
              active="Social"
              name1="Environment"
              link1="/environment"
              name2="Social"
              link2="/social"
              name3="Governance"
              link3="/governance"
            />
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <BorderedBox>
              <h2>SOCIAL INITIATIVES AT THE CHILDREN’S PLACE</h2>
              <p>
                At The Children’s Place, we embrace every child and every
                family. Our commitment to positive social practices includes
                investing in our associates, supporting the people who make our
                product and giving back to those in need. We work throughout our
                operations and with the global third-party vendors who
                manufacture our product in the following focus areas:
              </p>
              <ul class="list-inline list-bullet text-left">
                <li>
                  <b> WORKPLACE HEALTH &amp; SAFETY</b> Safeguarding our
                  associates and customers
                </li>
                <li>
                  <b>TALENT</b> Investing in the people that make our business
                  possible
                </li>
                <li>
                  <b> DIVERSITY, EQUITY &amp; INCLUSION</b> Building an
                  inclusive environment where all people feel welcomed and
                  valued
                </li>
                <li>
                  <b> COMMUNITY / PHILANTHROPY</b> Supporting children and
                  families in need
                </li>
                <li>
                  <b> SUPPLY CHAIN COMPLIANCE</b> Helping to improve the lives
                  of third-party factory workers and protect their rights in the
                  workplace
                </li>
                <li>
                  <b> WORKER WELL-BEING</b> Moving beyond the factory walls to
                  improve the well-being of workers and their families
                </li>
              </ul>

              <p>
                Details on these focus areas are available in our{' '}
                <a target="_blank" href={`${esgPdf2022}#page=39`}>
                  2022 Environment, Social &amp; Governance Report
                </a>{' '}
              </p>
            </BorderedBox>
          </div>
        </div>

        <div id="sourcinggroup">
          {/* safe working */}
          <div class="row mb-5 text-center">
            <div class="col-md-6 col-sm-6 col-xs-6 fill d-md-none">
              <img
                src={corporateimage2}
                title=""
                className="img-fluid"
                alt="..."
              />
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6">
              <Card title="GOALS" description="">
                <p>
                  Respect for human rights requires our industry to make
                  ambitious commitments to do more to support communities around
                  the world. We are working to meet this challenge through
                  initiatives focused on our associates, customers and the
                  third-party workers who manufacture our product. We have set
                  public social targets to demonstrate transparency and
                  accountability to our commitments, and drive progress in our
                  strategic focus areas.
                </p>
                <a target="_blank" href={`${esgPdf2022}#page=14`}>
                  <p>Learn more about our social goals</p>
                </a>

                {/* <button class="btn btn-primary more-less" type="button" data-toggle="collapse" data-target="#corpMoreone" aria-expanded="false" aria-controls=""></button> */}
              </Card>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 fill d-none d-md-flex">
              <img
                src={corporateimage2}
                title=""
                className="img-fluid"
                alt="..."
              />
            </div>
          </div>
          {/* education */}
          <div class="row mb-5 text-center">
            <div class="col-md-6 col-sm-6 col-xs-6">
              <img
                src={corporateimage3}
                title=""
                className="img-fluid"
                alt="..."
              />
            </div>

            <div class="col-md-6 col-sm-6 col-xs-6">
              <Card
                title="POLICIES"
                description="To support our commitment to ethical and responsible conduct in our global operations, we have developed policies and procedures that guide the actions of our associates and third-party vendors."
              >
                <a target="_blank" href={`${HumanRightsPolicy2021}`}>
                  <p>Human Rights Policy</p>
                </a>
                <a target="_blank" href={`${VendorCodeConduct2021}`}>
                  <p>Vendor Code of Conduct</p>
                </a>
                <a
                  target="_blank"
                  href="https://www.childrensplace.com/us/content/supply-chain"
                >
                  <p>2012 California Transparency in Supply Chains Act</p>
                </a>
                <a target="_blank" href={`${StatementXinjiang2021}`}>
                  <p>Statement on Xinjiang</p>
                </a>
                <a target="_blank" href={`${COVIDPandemic2021}`}>
                  <p>Working With Our Vendors During the COVID-19 Pandemic</p>
                </a>
                <a
                  target="_blank"
                  href={`${TCPOccupationalHealthSafetyPolicy}`}
                >
                  <p>Occupational Health &amp; Safety Policy</p>
                </a>
                <a
                  target="_blank"
                  href={`${CanadaForcedChildLabourSupplyChainsAct}`}
                >
                  <p>Canada Forced and Child Labour in Supply Chains Act</p>
                </a>

                {/* <a target="_blank" href={`${DEVELOPinitiative2021}`}><p>Learn more about our DEVELOP initiative</p></a> */}
                {/* <a target="_blank" href={`${esgPdf2022}#page=9`}><p>Learn more about our DEVELOP initiative</p></a> */}
                {/* <button class="btn btn-primary more-less" type="button" data-toggle="collapse" data-target="#corpMoretwo" aria-expanded="false" aria-controls=""></button> */}
              </Card>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mb-5">
              <hr class="d-md-none border-top" />
            </div>
          </div>
          {/* Environmental */}
          <div class="row mb-5 text-center">
            <div class="col-12">
              <h3>OUR INITIATIVE PARTNERS</h3>
              <p>
                <small>
                  Learn more about our ESG partners by clicking their logos
                  below.
                </small>
              </p>
            </div>
            <div class="col-sm-4 col-12 pl-5 pr-5">
              <button
                class="btn logo-btn close-button"
                type="button"
                data-toggle="collapse"
                data-target="#DonateMoreSeven"
                aria-expanded="false"
                aria-controls=""
              >
                <img
                  src={corporatesourcingimage1}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>
            <div class="col-sm-4 col-12 pl-5 pr-5">
              <div class="pt-4 d-none d-md-flex"></div>
              <button
                class="btn logo-btn close-button"
                type="button"
                data-toggle="collapse"
                data-target="#DonateMoreEight"
                aria-expanded="false"
                aria-controls=""
              >
                <img
                  src={corporatesourcingimage2}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>

            <div class="col-sm-4 col-12 pl-5 pr-5">
              <button
                class="btn logo-btn close-button"
                type="button"
                data-toggle="collapse"
                data-target="#DonateMoreTen"
                aria-expanded="false"
                aria-controls=""
              >
                <img
                  src={corporatesourcingimage10}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>
          </div>
          <div
            class="row mb-5 collapse"
            id="DonateMoreSeven"
            data-parent="#sourcinggroup"
          >
            <div class="col-md-12 col-sm-12 col-xs-12">
              {/* GOOD+ Foundation */}
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn logo-btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#DonateMoreSeven"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  Better Work is a global initiative mobilizing brands,
                  governments, factory owners and workers to improve working
                  conditions in the garment industry. The program, a partnership
                  between the UN's ILO and the IFC, a member of the World Bank
                  Group, manages factory assessments and trainings to empower
                  factories to improve the lives of their workers. The
                  Children's Place supports Better Work's priorities of setting
                  up structures to boost worker-management dialog and implement
                  lasting workplace improvements.
                </p>{' '}
                <p>
                  <a target="_blank" href="https://betterwork.org/global/">
                    Learn more about Better Work.
                  </a>
                </p>
              </BorderedBox>
            </div>
          </div>
          <div
            class="row mb-5 collapse"
            id="DonateMoreEight"
            data-parent="#sourcinggroup"
          >
            {/* IronMatt (The Matthew Larson Foundation)  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#DonateMoreEight"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  The Children's Place is a signatory of the Social &amp; Labor
                  Convergence Project (SLCP). This multi-stakeholder initiative
                  is a collaboration between manufacturers, retailers, industry
                  groups, and civil society organizations to develop a simple,
                  unified and effective assessment framework to factory
                  monitoring. Our shared goal is to increase the effectiveness
                  of factory audits, reduce audit fatigue and better utilize
                  collective resources in the apparel supply chain.
                </p>{' '}
                <p>
                  <a target="_blank" href="https://slconvergence.org/">
                    Learn more about SLCP.
                  </a>
                </p>
              </BorderedBox>
            </div>
          </div>
          <div
            class="row mb-5 collapse"
            id="DonateMoreTen"
            data-parent="#sourcinggroup"
          >
            {/* Youth Consultation Services (YCS)  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#DonateMoreNine"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  As a member of Nirapon, The Children’s Place is committed to
                  sustaining a culture of factory safety in Bangladesh. Building
                  on the work of the Alliance for Bangladesh Worker Safety,
                  Nirapon is a locally managed organization tasked with
                  overseeing the ongoing safety, training and helpline efforts
                  of the factories from which Nirapon members source.
                </p>{' '}
                <p>
                  <a target="_blank" href="https://www.nirapon.org/">
                    Learn more about Nirapon.
                  </a>
                </p>
              </BorderedBox>
            </div>
          </div>
          {/* end safe working */}
          <div class="row mb-5 text-center">
            <div class="col-sm-4  col-12 pl-5 pr-5">
              <button
                class="btn logo-btn close-button"
                type="button"
                data-toggle="collapse"
                data-target="#DonateMoreOne"
                aria-expanded="false"
                aria-controls=""
              >
                <img
                  src={corporatesourcingimage4}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>

            <div class="col-sm-4 col-12 pl-5 pr-5">
              <button
                class="btn logo-btn close-button"
                type="button"
                data-toggle="collapse"
                data-target="#PlanInternational"
                aria-expanded="false"
                aria-controls=""
              >
                <img
                  src={PlanInternational}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>

            <div class="col-sm-4 col-12 pl-5 pr-5">
              <button
                class="btn logo-btn close-button"
                type="button"
                data-toggle="collapse"
                data-target="#rise"
                aria-expanded="false"
                aria-controls=""
              >
                <img src={rise} title="" className="img-fluid" alt="..." />
              </button>
            </div>
          </div>
          {/* style={{ maxWidth: 100 }} */}

          <div class="row mb-5 collapse" id="rise" data-parent="#sourcinggroup">
            {/* Youth Consultation Services (YCS)  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#rise "
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  RISE (Reimagining Industry to Support Equality) is an
                  initiative to support collaborative industry action at scale
                  to advance gender equality in global garment, footwear, and
                  home textiles supply chain. RISE's vision is that women
                  working in global supply chains experience greater dignity,
                  equality, greater choice of opportunities and fulfilment of
                  their rights. Hyperlink should read Learn more about RISE.
                </p>{' '}
                <p>
                  <a target="_blank" href="https://riseequal.org/">
                    Learn more about RISE.
                  </a>
                </p>
              </BorderedBox>
            </div>
          </div>
          <div
            class="row mb-5 collapse"
            id="PlanInternational"
            data-parent="#sourcinggroup"
          >
            {/* Youth Consultation Services (YCS)  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#PlanInternational "
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  Powered by supporters, Plan International USA partners with
                  adolescent girls, young women and children around the world to
                  overcome oppression and gender inequality, providing the
                  support and resources that are unique to their needs and the
                  needs of their communities, ensuring they achieve their full
                  potential with dignity, opportunity and safety. Founded in
                  1937, Plan International is an independent development and
                  humanitarian organization that is active in more than 80
                  countries.
                </p>{' '}
                <p>
                  <a target="_blank" href="https://www.planusa.org/">
                    Learn more about Plan International USA.
                  </a>
                </p>
              </BorderedBox>
            </div>
          </div>
          <div
            class="row mb-5 collapse"
            id="DonateMoreOne"
            data-parent="#sourcinggroup"
          >
            <div class="col-md-12 col-sm-12 col-xs-12">
              {/* GOOD+ Foundation */}
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#DonateMoreOne"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  BSR's HERproject aims to unlock the full potential of garment
                  workers through workplace programs promoting health, financial
                  inclusion and gender equality. Through HERproject, The
                  Children’s Place partners with its global vendors and local
                  NGOs to help drive positive impact for women and for its
                  business.
                </p>
                <p>
                  <a target="_blank" href="https://herproject.org/">
                    Learn more about HERproject.
                  </a>
                </p>
              </BorderedBox>
            </div>
          </div>
          <div
            class="row mb-5 collapse"
            id="DonateMoreThree"
            data-parent="#sourcinggroup"
          >
            {/* Youth Consultation Services (YCS)  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#DonateMoreThree"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  The Centre for Child Rights and Business (shortened as 'The
                  Centre’ and formerly known as CCR CSR) supports businesses to
                  deliver improvements within their supply chains that not only
                  benefit workers, families and children, but also deliver
                  positive business outcomes. The Centre offers global
                  expertise, services and support covering a broad range of
                  child rights and worker well-being issues. The Children’s
                  Place partners with The Centre on programs focused on parent
                  training and childcare with our Vendor Factories.
                </p>
                <p>
                  <a href="https://www.ccrcsr.com/">
                    Learn more about The Centre.
                  </a>
                </p>
              </BorderedBox>
            </div>
          </div>
          {/* end education */}
          <div class="row">
            <div class="col-12 mb-5">
              <hr class="d-md-none border-top" />
            </div>
          </div>
          <div class="row mb-5 text-center">
            <div class="col-12">
              <h3>OUR PHILANTHROPIC PARTNERS</h3>
              <p>
                <small>
                  Learn more about our ESG partners by clicking their logos
                  below.
                </small>
              </p>
            </div>

            <div class="col-md-3 col-sm-3 col-xs-4">
              <button
                class="btn close-button"
                type="button"
                data-toggle="collapse"
                data-target="#DonateMoreOneOne"
                aria-expanded="false"
                aria-controls=""
              >
                {' '}
                <img
                  src={corporatephilanthropicimage6}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-4">
              <button
                class="btn close-button"
                type="button"
                data-toggle="collapse"
                data-target="#DonateMoreTwoOne"
                aria-expanded="false"
                aria-controls=""
              >
                {' '}
                <img
                  src={corporatephilanthropicimage7}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-4">
              <button
                class="btn close-button"
                type="button"
                data-toggle="collapse"
                data-target="#DonateMoreThreeOne"
                aria-expanded="false"
                aria-controls=""
              >
                {' '}
                <img
                  src={corporatephilanthropicimage8}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>
            <div class="col-md-3 col-sm-3 col-xs-4">
              <button
                class="btn close-button"
                type="button"
                data-toggle="collapse"
                data-target="#DonateMoreTwotwo"
                aria-expanded="false"
                aria-controls=""
              >
                {' '}
                <img
                  src={corporatephilanthropicimage13}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>
          </div>
          <div
            class="row mb-5 collapse"
            id="DonateMoreOneOne"
            data-parent="#sourcinggroup"
          >
            <div class="col-md-12 col-sm-12 col-xs-12">
              {/* GOOD+ Foundation */}
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#DonateMoreOneOne"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  Since 2001, The Children’s Place has donated more than $2M in
                  clothing to the Good+ Foundation to distribute throughout
                  their national network of partners. In 2017, we began
                  sponsoring their “Shop with Pops” program by providing dads
                  with gift cards to use during a day of shopping and bonding
                  with their kids.
                  <a target="_blank" href="https://goodplusfoundation.org/">
                    {' '}
                    Learn More
                  </a>
                </p>
              </BorderedBox>
            </div>
          </div>
          <div
            class="row mb-5 collapse"
            id="DonateMoreTwoOne"
            data-parent="#sourcinggroup"
          >
            {/* IronMatt (The Matthew Larson Foundation)  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#DonateMoreTwoOne"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  The Children’s Place is proud to help families supported by
                  IronMatt’s assistance program. Every month, Associates pack up
                  new pajamas for each “IronKid” undergoing pediatric brain
                  cancer treatment. $100 Gift cards are also provided to each
                  IronKid (and their siblings!) so they can all treat themselves
                  to new outfits. In addition, we donate to Iron Matt’s annual
                  fundraising event.
                  <a target="_blank" href="https://www.ironmatt.org/home">
                    {' '}
                    Learn More
                  </a>
                </p>
              </BorderedBox>
            </div>
          </div>
          <div
            class="row mb-5 collapse"
            id="DonateMoreThreeOne"
            data-parent="#sourcinggroup"
          >
            {/* Youth Consultation Services (YCS)  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#DonateMoreThreeOne"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  The Children’s Place provides corporate donations and matches
                  Associate gifts in support of the NAACP’s mission to secure
                  equality of rights in order to eliminate race-based
                  discrimination and ensure the health and well-being of all
                  persons.{' '}
                  <a
                    target="_blank"
                    href="https://naacp.org/empowerment-programs/"
                  >
                    Learn More
                  </a>
                </p>
              </BorderedBox>
            </div>
          </div>
          <div
            class="row mb-5 collapse"
            id="DonateMoreTwotwo"
            data-parent="#sourcinggroup"
          >
            {}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#DonateMoreTwotwo"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  Delivering Good unites retailers, manufacturers, foundations
                  and individuals to provide people impacted by poverty and
                  tragedy with new merchandise, effectively distributed through
                  a network of agency partners to offer hope, dignity and
                  self-esteem to at-risk children, families and individuals.
                </p>
                <a target="_blank" href="https://www.delivering-good.org/">
                  Learn more
                </a>
              </BorderedBox>
            </div>
          </div>
          <div class="row mb-5 text-center">
            <div class="col-md-3 col-sm-3 col-xs-4">
              <button
                class="btn close-button text-center"
                type="button"
                data-toggle="collapse"
                data-target="#DonateMoreFourFour"
                aria-expanded="false"
                aria-controls=""
              >
                {' '}
                <img
                  src={corporatephilanthropicimage9}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>

            <div class="col-md-3 col-sm-3 col-xs-4">
              <button
                class="btn close-button text-center"
                type="button"
                data-toggle="collapse"
                data-target="#DonateMoreFiveFour"
                aria-expanded="false"
                aria-controls=""
              >
                {' '}
                <img
                  src={corporatephilanthropicimage11}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>

            <div class="col-md-3 col-sm-3 col-xs-4">
              <button
                class="btn close-button text-center"
                type="button"
                data-toggle="collapse"
                data-target="#DonateMoreSixFour"
                aria-expanded="false"
                aria-controls=""
              >
                {' '}
                <img
                  src={corporatephilanthropicimage12}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>

            <div class="col-md-3 col-sm-3 col-xs-4">
              <button
                class="btn close-button text-center"
                type="button"
                data-toggle="collapse"
                data-target="#DonateMoreSevenFour"
                aria-expanded="false"
                aria-controls=""
              >
                {' '}
                <img
                  src={corporatephilanthropicimage10}
                  title=""
                  className="img-fluid"
                  alt="..."
                />
              </button>
            </div>
          </div>
          <div
            class="row mb-5 collapse"
            id="DonateMoreFourFour"
            data-parent="#sourcinggroup"
          >
            {/* Table to Table  */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#DonateMoreFourFour"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  The Children’s Place is a Table to Table Silver Sponsor and
                  the top donor to the yearly “Bag a Lunch, Help a Bunch”
                  program. And every Thanksgiving, our Associates donate money
                  they would have spent on lunch to Table to Table which The
                  Children’s Place matches.
                  <a
                    target="_blank"
                    href="https://tabletotable.org/who-we-are/mission/"
                  >
                    {' '}
                    Learn More
                  </a>
                </p>
              </BorderedBox>
            </div>
          </div>
          <div
            class="row mb-5 collapse"
            id="DonateMoreFiveFour"
            data-parent="#sourcinggroup"
          >
            {/* Children's Aid and Family Services (CAFS) */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#DonateMoreFiveFour"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  In 2019, The Children’s Place became the corporate sponsor of
                  the CAFS “Baby Basics” program which provides diapers, formula
                  and other necessities to children up to the age of 2.{' '}
                  <a target="_blank" href="http://cafsnj.org/">
                    {' '}
                    Learn More
                  </a>
                </p>
              </BorderedBox>
            </div>
          </div>
          <div
            class="row mb-5 collapse"
            id="DonateMoreSixFour"
            data-parent="#sourcinggroup"
          >
            {/* Alex’s Lemonade Stand */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#DonateMoreSixFour"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  Over the past 17 years (and counting!), The Children’s Place
                  has covered the full cost to send twenty children in the YCS
                  program to summer camp. We also donate new clothing to all the
                  YCS kids, as well as grant holiday gift wishes to over 150
                  children in their programs.{' '}
                  <a target="_blank" href="http://www.ycs.org/">
                    Learn More
                  </a>
                </p>
              </BorderedBox>
            </div>
          </div>
          <div
            class="row mb-5 collapse"
            id="DonateMoreSevenFour"
            data-parent="#sourcinggroup"
          >
            {/* Secaucus */}
            <div class="col-md-12 col-sm-12 col-xs-12">
              <BorderedBox classname="mb-n-border">
                <button
                  class="btn close-button"
                  type="button"
                  data-toggle="collapse"
                  data-target="#DonateMoreSevenFour"
                  aria-expanded="false"
                  aria-controls=""
                >
                  X
                </button>
                <p>
                  Our corporate headquarters is located in the heart of
                  Secaucus, NJ and we are proud to support the Secaucus Youth
                  Alliance by donating clothing throughout the year and
                  providing money to purchase toys for local kids in need during
                  the holidays.
                </p>
              </BorderedBox>
            </div>
          </div>
        </div>

        {/* end education */}
      </Layout>
    )
  }
}

export default ResponsibleSourcing
